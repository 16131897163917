import React, { useEffect } from 'react';

import gsap from 'gsap';

import { Wrapper, Logo, Lang, Toggle } from './Header.styles';
import Nav from './nav';
import SecondNav from './secondNav';
import { Link } from 'gatsby';

const Header = (props) => {
  //PROPS
  const { dimensions, lang, isMenuOpen, setMenuOpen } = props;
  //PROPS

  //ANIMS
  const tl = gsap.timeline();

  const openMenu = () => {
    tl.to('.span ', {
      duration: 0.22,
      css: { top: 7 },
    })
      .to('.span--top', {
        duration: 0.22,
        rotation: 45,
      })
      .to('.span--bottom', {
        duration: 0.22,
        delay: -0.22,
        rotation: -45,
      })
      .to('.span--center', {
        duration: 0.22,
        delay: -0.22,
        opacity: 0,
      });
  };

  const closeMenu = () => {
    tl.to('.span', {
      duration: 0.22,
      rotation: 0,
    })
      .to('.span--top', {
        duration: 0.22,
        css: { top: 0 },
      })
      .to('.span--bottom', {
        duration: 0.22,
        delay: -0.22,
        css: { top: 14 },
      })
      .to('.span--center', {
        duration: 0.22,
        delay: -0.22,
        opacity: 1,
      });
  };

  //ANIMS

  useEffect(() => {
    if (isMenuOpen && dimensions.width) {
      openMenu();
    } else if (dimensions.width) {
      closeMenu();
    }
  }, [isMenuOpen, dimensions]);

  useEffect(() => {
    if (dimensions.width >= 1024) {
      setMenuOpen(false);
    }
  }, [dimensions]);

  return (
    <Wrapper id="header">
      <Logo isMenuOpen={isMenuOpen}>
        <Link to={lang === 'PT' ? '/' : '/en/'}>MONARQ</Link>
      </Logo>
      <Nav
        dimensions={dimensions}
        lang={lang}
        isMenuOpen={isMenuOpen}
        setMenuOpen={setMenuOpen}
      />
      <SecondNav dimensions={dimensions} lang={lang} isMenuOpen={isMenuOpen} />
      <Toggle
        isMenuOpen={isMenuOpen}
        onClick={() => {
          setMenuOpen(!isMenuOpen);
        }}
      >
        <span className="span span--top"></span>
        <span className="span span--center"></span>
        <span className="span span--bottom"></span>
      </Toggle>
      <Lang isMenuOpen={isMenuOpen}>
        <li className="active">PT</li>
        <li>EN</li>
      </Lang>
    </Wrapper>
  );
};

export default Header;
