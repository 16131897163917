import styled from 'styled-components';

import { white, black } from 'theme/colors';

export const FContainer = styled.footer`
  background-color: ${white.default};
  .footer-main {
    display: flex;
    padding: 28px 30px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    &__left {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: flex-start;
      &__col {
        &:last-of-type {
          margin-top: 40px;
        }
      }
    }
    &__right {
      display: none;
      position: relative;
      width: 100%;
      max-width: 50vw;
      min-width: 450px;
      justify-content: flex-end;
      align-items: flex-start;
      p {
        padding: 0 20px;
        display: inline;
        margin: 0;
        position: relative;
        line-height: 1;
        background-color: ${white.default};
      }
      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        top: 7px;
        left: 0;
        background-color: ${black.default};
      }
    }
  }
  @media (min-width: 1024px) {
    .footer-main {
      flex-direction: row;
      align-items: stretch;
      justify-content: space-between;
      &__left {
        flex-direction: row;
        align-items: flex-start;
        &__col {
          &:last-of-type {
            margin-top: 0;
            margin-left: 40px;
          }
        }
        &__right {
          display: flex;
        }
      }
    }
  }
`;
