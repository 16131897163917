import React from 'react';

import { Link, useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import { NavLi, Wrapper, Sub } from './Nav.styles';

const Nav = (props) => {
  //PROPS
  const { lang, isMenuOpen, setMenuOpen, dimensions } = props;
  //PROPS

  //DATA
  const data = useStaticQuery(graphql`
    query Menu {
      allWpPage {
        nodes {
          menu {
            menuItem {
              tipo
              link {
                url
                title
              }
              sublinkambientes {
                ... on WpPost {
                  title
                  seo {
                    slug
                  }
                }
              }
              sublinkprodutos {
                ... on WpProduto {
                  title
                  seo {
                    slug
                  }
                }
              }
              hero {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(height: 200)
                  }
                }
              }
            }
          }
          language {
            code
          }
        }
      }
    }
  `);

  const {
    allWpPage: { nodes },
  } = data;
  //DATA

  //DEFS
  let info = nodes
    .filter((item) => {
      if (lang) {
        return item.language.code === lang;
      }
    })
    .filter(
      (item) =>
        //lang === "EN" ? "Main Menu PT" : "Main Menu EN"
        item?.menu?.menuItem
    );

  let menu = info[0]?.menu.menuItem;
  //DEFS

  return (
    <Wrapper className="navContainer" isMenuOpen={isMenuOpen}>
      {lang && (
        <ul className="menu">
          {menu &&
            menu.map((item, index) => {
              return (
                <NavLi className="nav-column" key={index}>
                  <Link to={item.link.url} onClick={() => setMenuOpen(false)}>
                    {item.link.title}
                  </Link>

                  {item.tipo !== 'Normal' && (
                    <Sub className="sub">
                      {item.tipo === 'ambientes' && (
                        <>
                          <ul>
                            {item.sublinkambientes &&
                              item.sublinkambientes.map((sub, index) => {
                                return (
                                  <li key={index}>
                                    <Link to={sub.seo.slug}>{sub.title}</Link>
                                  </li>
                                );
                              })}
                          </ul>
                          <GatsbyImage
                            image={
                              item?.hero?.localFile?.childImageSharp
                                ?.gatsbyImageData
                            }
                            alt={item?.hero?.altText}
                          />
                        </>
                      )}
                      {item.tipo === 'produtos' && (
                        <>
                          <ul>
                            {item.sublinkprodutos &&
                              item.sublinkprodutos.map((sub, index) => {
                                return (
                                  <li key={index}>
                                    <Link to={sub.seo.slug}>{sub.title}</Link>
                                  </li>
                                );
                              })}
                          </ul>
                          <GatsbyImage
                            image={
                              item?.hero?.localFile?.childImageSharp
                                ?.gatsbyImageData
                            }
                            alt={item?.hero?.altText}
                          />
                        </>
                      )}
                    </Sub>
                  )}
                </NavLi>
              );
            })}
        </ul>
      )}
    </Wrapper>
  );
};

export default Nav;
