import { toRem } from "utils/mixins";

export const space = {
  small: toRem(8),
  base: toRem(16),
  medium: toRem(72),
  big: toRem(152),
  xl: toRem(240),
};

export const container = {
  default: toRem(1440),
  small: toRem(1290),
  xsmall: toRem(640),
};
