import styled from 'styled-components';

import { grey, black, white } from 'theme/colors';

export const Wrapper = styled.div`
  position: relative;
  padding: 28px 30px;
  background-color: ${grey.default};
  z-index: 4;
  div {
    a {
      display: block;
      border: 1px solid ${black.default};
      color: ${black.default};
      &:last-of-type {
        margin-top: 10px;
      }
    }
  }
  p {
    color: ${black.default};
  }
  @media (min-width: 1024px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    div {
      a {
        display: inline-block;
        &:last-of-type {
          margin-top: 0;
          margin-left: 10px;
        }
      }
    }
  }
`;
