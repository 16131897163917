export const white = {
  default: '#f5f5f5',
};

export const black = {
  default: '#373431',
};

export const grey = {
  default: '#e6e6e6',
  darker: '#44413d',
};

export const blue = {
  default: '#0C1A27',
};

export const red = {
  default: '#E07167',
};
