import React from 'react';
import AfterFooter from './afterFooter/AfterFooter';

import { FContainer } from './Footer.styles';

const Footer = (props) => {
  //PROPS
  const { lang, theme } = props;
  //PROPS

  // //DATA
  // const data = useStaticQuery(graphql`
  //   query Footer {
  //     allWpPage(filter: { id: { eq: "cG9zdDo1MTE=" } }) {
  //       edges {
  //         node {
  //           id
  //           footer {
  //             address
  //             socialMedia {
  //               smedia {
  //                 target
  //                 title
  //                 url
  //               }
  //             }
  //             logo {
  //               localFile {
  //                 childImageSharp {
  //                   original {
  //                     src
  //                   }
  //                 }
  //               }
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // `);

  // const {
  //   allWpPage: { edges },
  // } = data;
  // //DATA

  //DEFS
  //const footer = edges[0].node.footer;
  //DEFS

  return (
    <FContainer theme={theme}>
      {/* <div className="footer-main">
        <div className="footer-main__left">
          <div className="footer-main__left__col">
            <h3>Monarq</h3>
            <p>
              Bahrenfelder Steindamm 67 A<br /> 22761 Hamburg
            </p>
            <p> + 49 (0) 40 66 06 22</p>
            <button className="button">Contact Us</button>
          </div>
          <div className="footer-main__left__col">
            <h3>Newsletter</h3>

            <label>
              <input className="input" placeholder="email" />
            </label>
          </div>
        </div>
        <div className="footer-main__right">
          <p>Furniture since 1993</p>
        </div>
      </div> */}
      <AfterFooter lang={lang} theme={theme} />
    </FContainer>
  );
};

export default Footer;
