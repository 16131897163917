import styled from 'styled-components';

import { black, white } from 'theme/colors';

export const Wrapper = styled.nav`
  z-index: 10;
  overflow: visible;
  background-color: ${white.default};
  height: 100vh;
  width: 100vw;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: ${(props) =>
    props.isMenuOpen ? 'translateY(0)' : 'translateY(-100%)'};
  @media (min-width: 1024px) {
    background-color: transparent;
    height: auto;
    width: auto;
    position: relative;
    left: unset;
    top: unset;
    display: block;
    align-items: unset;
    justify-content: unset;
    margin-left: 40px;
    transform: translateY(0);
    .menu {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      height: 84px;
    }
  }
`;

export const NavLi = styled.li`
  line-height: 0;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;

  a {
    color: ${black.default};
    text-transform: uppercase;
    font-family: 'Nexa';
    line-height: normal;
    position: relative;
    font-weight: 300;
    font-size: 26px;

    &::before {
      content: '';
      position: absolute;
      width: 0;
      height: 1px;
      display: block;
      bottom: 0;
      right: 0;
      transition: 0.4s ease;
      background-color: ${white.default};
    }

    &:hover {
      &::before {
        width: 100%;
        left: 0;
      }
    }
  }

  &:hover {
    .sub {
      opacity: 1;
      pointer-events: all;
    }
  }

  @media (min-width: 1024px) {
    margin-left: 20px;
    margin-bottom: 0;
    justify-content: flex-start;

    a {
      text-align: left;
      font-size: 12px;
      line-height: 12px;
      color: ${black.default};
      &::before {
        background-color: ${black.default};
      }
    }
  }
`;

export const Sub = styled.div`
  position: fixed;
  top: 85px;
  left: 0;
  opacity: 0;
  transition: 250ms;
  width: 100vw;
  background-color: ${'#e6e6e6'};
  padding: 60px;
  align-items: center;
  justify-content: center;
  display: none;
  pointer-events: none;
  ul {
    display: flex;
    flex-direction: column;
    margin-right: 120px;
    li {
      margin-bottom: 14px;
    }
  }
  @media (min-width: 1024px) {
    display: flex;
    transform: translateX(-145px);
  }
`;
