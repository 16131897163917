import React from "react";
import { Helmet } from "react-helmet";

const CreateHead = (props) => {
  const headInfo = {
    title: props.title
      ? props.title + " | Monarq Furniture"
      : "Monarq Furniture",
    description: props.description
      ? props.description
      : "A devstudio é uma agência de design do Porto. Focados no processo criativo e a sua aplicação no mundo real.",
    url: props.url ? props.url : "https://devstudio.com.pt",
    image: props.img ? props.img : "/banner.png",
  };

  return (
    <Helmet>
      <title>{headInfo.title}</title>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, user-scalable=no"
      />
      <meta name="description" content={headInfo.description} />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#0c1a27" />
      <meta name="msapplication-TileColor" content="#f5f5f5" />
      <meta name="theme-color" content="#ffffff" />
      <meta property="og:url" content={headInfo.url} />
      <meta property="og:title" content={headInfo.title} />
      <meta property="og:description" content={headInfo.description} />
      <link rel="image_src" href={headInfo.image}></link>
      <meta property="og:image" content={headInfo.image} />
      <meta property="og:site_name" content={headInfo.title} />
      <meta name="twitter:title" content={headInfo.title} />
      <meta name="twitter:description" content={headInfo.description} />
      <meta name="twitter:image" content={headInfo.image} />
      <meta name="twitter:card" content="summary_large_image"></meta>
      <meta name="twitter:image:alt" content={headInfo.title}></meta>
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link
        href="https://fonts.googleapis.com/css2?family=Gilda+Display&family=Open+Sans:wght@300;400;700&display=swap"
        rel="stylesheet"
      />
      <link
        rel="stylesheet"
        type="text/css"
        charset="UTF-8"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />
      {props.children}
    </Helmet>
  );
};
export default CreateHead;
