// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-custom-ambientes-template-js": () => import("./../../../src/templates/custom/AmbientesTemplate.js" /* webpackChunkName: "component---src-templates-custom-ambientes-template-js" */),
  "component---src-templates-custom-contacts-template-js": () => import("./../../../src/templates/custom/ContactsTemplate.js" /* webpackChunkName: "component---src-templates-custom-contacts-template-js" */),
  "component---src-templates-custom-fabrica-template-js": () => import("./../../../src/templates/custom/FabricaTemplate.js" /* webpackChunkName: "component---src-templates-custom-fabrica-template-js" */),
  "component---src-templates-custom-homepage-template-js": () => import("./../../../src/templates/custom/HomepageTemplate.js" /* webpackChunkName: "component---src-templates-custom-homepage-template-js" */),
  "component---src-templates-custom-privacy-template-js": () => import("./../../../src/templates/custom/PrivacyTemplate.js" /* webpackChunkName: "component---src-templates-custom-privacy-template-js" */),
  "component---src-templates-custom-produtos-template-js": () => import("./../../../src/templates/custom/ProdutosTemplate.js" /* webpackChunkName: "component---src-templates-custom-produtos-template-js" */),
  "component---src-templates-custom-wishlist-template-js": () => import("./../../../src/templates/custom/WishlistTemplate.js" /* webpackChunkName: "component---src-templates-custom-wishlist-template-js" */),
  "component---src-templates-general-post-js": () => import("./../../../src/templates/general/Post.js" /* webpackChunkName: "component---src-templates-general-post-js" */),
  "component---src-templates-general-produto-js": () => import("./../../../src/templates/general/Produto.js" /* webpackChunkName: "component---src-templates-general-produto-js" */)
}

