import styled from 'styled-components';

import { white, black } from 'theme/colors';

export const Wrapper = styled.header`
  position: fixed;
  width: 100%;
  padding: 0 20px;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 20;
  transition: 150ms;
  background-color: ${white.default};
  border-bottom: ${`1px solid ${white.default}`};

  @media (min-width: 1024px) {
    justify-content: unset;
  }
`;

export const Logo = styled.div`
  padding: 20px 0;
  position: relative;
  z-index: 21;
  a {
    line-height: 1;
    font-family: 'Gilda Display', serif;
    color: ${black.default};
  }
`;

export const Toggle = styled.button`
  position: relative;
  cursor: pointer;
  z-index: 21;
  width: 20px;
  height: 16px;
  display: block;
  .span {
    top: 7px;
    background-color: ${black.default};
    width: 20px;
    height: 2px;
    display: block;
    position: absolute;
    &--top {
      position: absolute;
      top: 0px;
    }
    &--bottom {
      position: absolute;
      top: 14px;
    }
  }
  @media (min-width: 1024px) {
    display: none;
  }
`;

export const Lang = styled.ul`
  position: absolute;
  right: 80px;
  top: 0;
  z-index: 21;
  display: flex;

  li {
    text-transform: uppercase;
    font-family: 'Nexa';
    line-height: normal;
    position: relative;
    font-weight: 300;
    padding-top: 4px;
    font-size: 13px;
    position: relative;
    color: ${black.default};

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 3px;
      background-color: transparent;
    }
    &:last-of-type {
      margin-left: 10px;
    }
  }
  .active {
    &::after {
      background-color: ${black.default};
    }
  }

  @media (min-width: 1024px) {
    right: 400px;
    li {
      color: ${black.default};
    }
    .active {
      &::after {
        background-color: ${black.default};
      }
    }
  }
`;
