import React, { useEffect, useState } from 'react';

import gsap from 'gsap';
import { AnimatePresence } from 'framer-motion';

import { GlobalStyles } from 'theme/global';

import CookieBar from 'components/cookieBar';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import CreateHead from '../components/createHeader/CreateHead';

//UTILITY
function debounce(fn, ms) {
  let timer;
  return () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}
//UTILITY

const Layout = (props) => {
  const { location } = props;
  //STATE

  const [isMenuOpen, setMenuOpen] = useState(false);
  const [dimensions, setDimension] = useState({
    height: '',
    width: '',
  });
  const [scroll, setScroll] = useState(false);
  //STATE

  //DEFS
  const seo = props.pageContext?.seo;
  //DEFS

  useEffect(() => {
    let ticking = false;

    const updateScrollDir = () => {
      const scrollY = window.pageYOffset;
      scrollY > 150 ? setScroll(true) : setScroll(false);
      ticking = false;

      return;
    };

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    };

    if (props.pageContext?.template?.templateName === 'Homepage Template') {
      window.addEventListener('scroll', onScroll, true);
    } else {
      setScroll(true);
    }

    return () => {
      window.removeEventListener('scroll', onScroll, true);
    };
  }, [location]);

  //EFFECT
  useEffect(() => {
    gsap.to('body', {
      duration: 0,
      css: { visibility: 'visible', opacity: 1 },
    });

    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    const debouncedHandleResize = debounce(function handleResize() {
      setDimension({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }, 100);

    window.addEventListener('resize', debouncedHandleResize);

    setDimension({
      height: window.innerHeight,
      width: window.innerWidth,
    });

    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  }, []);

  //EFFECT

  //CHILDREN MODIFIER
  const childrenWithProps = React.Children.map(props.children, (child) => {
    return React.cloneElement(child, {
      dimensions: dimensions,
      lang: props.pageContext?.language?.code,
    });
  });
  //CHILDREN MODIFIER

  return (
    <>
      <GlobalStyles />
      <CreateHead
        title={seo?.seoTitle}
        description={seo?.seoDescription}
        image={seo?.seoImage}
      />
      <Header
        scroll={scroll}
        dimensions={dimensions}
        lang={props.pageContext?.language?.code}
        isMenuOpen={isMenuOpen}
        setMenuOpen={setMenuOpen}
      />
      <main className="main">
        <AnimatePresence initial={false}>{childrenWithProps}</AnimatePresence>
      </main>
      <Footer lang={props.pageContext?.language?.code} />
      <CookieBar translations={null} />
    </>
  );
};

export default Layout;
