import styled from 'styled-components';

import { black, white } from 'theme/colors';

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  transform: translateY(calc(100vh - 80px));
  z-index: 21;
  left: 0;
  width: 100vw;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${(props) => (props.isMenuOpen ? '1' : '0')};
  .menu {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
  }

  @media (min-width: 1024px) {
    opacity: 1;
    position: relative;
    top: unset;
    transform: none;
    left: unset;
    width: unset;
    height: unset;
    display: block;
    align-items: center;
    justify-content: unset;
    margin-left: auto;
  }
`;

export const NavLi = styled.li`
  line-height: 0;
  padding: 14px;
  text-align: center;
  a,
  button {
    color: ${white.default};
    text-transform: uppercase;
    font-family: 'Nexa';
    line-height: normal;
    position: relative;
    font-weight: 300;
    font-size: 12px;
    line-height: 12px;
    &::before {
      content: '';
      position: absolute;
      width: 0;
      height: 1px;
      display: block;
      bottom: 0;
      right: 0;
      transition: 0.4s ease;
      background-color: ${black.default};
    }
    &:hover {
      &::before {
        width: 100%;
        left: 0;
      }
    }
  }

  @media (min-width: 1024px) {
    padding: 0;
    margin-left: 20px;
    a,
    button {
      color: ${black.default};
      font-size: 12px;
      line-height: 12px;
      &::before {
        background-color: ${black.default};
      }
    }
  }
`;
