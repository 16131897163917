import React from 'react';

import { Link, useStaticQuery, graphql } from 'gatsby';

import { NavLi, Wrapper } from './SecondNav.styles';

const SecondNav = (props) => {
  //PROPS
  const { lang, isMenuOpen } = props;
  //PROPS

  //DATA
  const data = useStaticQuery(graphql`
    query secondNav {
      allWpMenu {
        edges {
          node {
            menuItems {
              nodes {
                label
                url
                connectedNode {
                  node {
                    id
                  }
                }
              }
            }
            name
          }
        }
      }
    }
  `);

  const {
    allWpMenu: { edges },
  } = data;
  //DATA

  //DEFS
  let info = edges.filter((item) =>
    //lang === "EN" ? "Main Menu PT" : "Main Menu EN"
    item.node.name.search('Second Menu PT') !== -1 ? item : null
  );

  let menu = info[0]?.node?.menuItems?.nodes;

  //DEFS

  return (
    <Wrapper className="navContainer" isMenuOpen={isMenuOpen}>
      {lang && (
        <ul className="menu">
          {menu.map((item, index) => {
            return (
              <NavLi className="nav-column" key={index} isMenuOpen={isMenuOpen}>
                <Link to={item.url}>{item.label}</Link>
              </NavLi>
            );
          })}
        </ul>
      )}
    </Wrapper>
  );
};

export default SecondNav;
