import React from 'react';

import { Link } from 'gatsby';

import { Wrapper } from './AfterFooter.styles';

const AfterFooter = (props) => {
  //PROPS
  const { lang, theme } = props;
  //PROPS

  return (
    <Wrapper theme={theme}>
      <p>Copyright © 2021 Monarq, Lda. All rights reserved.</p>
      <div>
        {lang === 'PT' ? (
          <Link
            className="button button--secondary"
            to="/politica-de-privacidade"
          >
            Política de Privacidade
          </Link>
        ) : (
          <Link className="button button--secondary" to="/en/privacy-policy">
            Policy
          </Link>
        )}
      </div>
    </Wrapper>
  );
};

export default AfterFooter;
